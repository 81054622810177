/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Gatti Filtrazioni
 * OpenAPI spec version: v1
 */

export type SogliaCheckType =
  (typeof SogliaCheckType)[keyof typeof SogliaCheckType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SogliaCheckType = {
  LessThan: "LessThan",
  MoreThan: "MoreThan",
} as const;
