import { _dispositivoTypeUtilsData } from "./dispositivoTypeUtilsData";
import {
  BitmaskMappaturaAllarmi,
  DispositivoDto,
  DispositivoType,
  EqualityMappaturaAllarmi,
  ModelloDispositivoDto,
} from "@/api";

export interface DispositivoTypeHelper {
  label: string;
}

const dispositivoTypesHelper: {
  [type in DispositivoType]: DispositivoTypeHelper;
} = {
  unknown: generateHelper(_dispositivoTypeUtilsData.Unknown),
  macchinario: generateHelper(_dispositivoTypeUtilsData.Macchinario),
  sensore: generateHelper(_dispositivoTypeUtilsData.Sensore),
} as const;

export const dispositivoTypes = Object.keys(
  dispositivoTypesHelper
) as DispositivoType[];

export function getDispositivoTypeHelper(
  type: DispositivoType
): DispositivoTypeHelper | null {
  // if (type === "unknown") {
  //   return null;
  // }
  const h = dispositivoTypesHelper[type];
  if (!h) {
    console.error("[DispositivoTypeHelper] Unknown type: " + type);
  }
  return h || null;
}

export type MacchnarioStatus = "off" | "stop" | "on" | "on-manuale" | "alarm";
export type SensoreStatus = "off" | "on" | "alarm";
export type DispositivoStatus = MacchnarioStatus | SensoreStatus;

// -------------------------------------------------
// -------------------------------------------------
// -------------------------------------------------

function generateHelper(
  d: (typeof _dispositivoTypeUtilsData)[keyof typeof _dispositivoTypeUtilsData]
): DispositivoTypeHelper {
  return {
    label: d.label,
  };
}

export function getDispositivoTypeLabel(type: DispositivoType): string {
  return getDispositivoTypeHelper(type)!.label;
}

// -------------------------------------------------
// -------------------------------------------------
// -------------------------------------------------

export function getAllarmiDispositivo(
  modelloDispositivo: ModelloDispositivoDto | null | undefined,
  dispositivo: DispositivoDto
): string[] | null {
  const hasAlarmStatus = dispositivo.lastStatus === "alarm";
  const hasAlarmCode =
    typeof dispositivo.lastAlarmStatus === "string" &&
    dispositivo.lastAlarmStatus !== "" &&
    dispositivo.lastAlarmStatus !== "0";

  if (hasAlarmCode) {
    if (!modelloDispositivo) {
      // non conosciamo questo modello, facciamo fallback sul codice inviato dal dispositivo
      return [dispositivo.lastAlarmStatus!];
    }

    const allarmi = modelloDispositivo.mappaturaAllarmi;

    if (allarmi?.type === "Bitmask") {
      const config = allarmi as BitmaskMappaturaAllarmi;
      const statusNumber = parseInt(dispositivo.lastAlarmStatus!, 10);

      if (isNaN(statusNumber)) {
        // fallback
        return [dispositivo.lastAlarmStatus!];
      }

      const messages: string[] = [];

      for (const bit of config.bits ?? []) {
        if (typeof bit.index === "number" && bit.message) {
          const isBitSet = (statusNumber & (1 << bit.index)) !== 0;
          if (isBitSet) {
            messages.push(bit.message);
          }
        }
      }

      return messages;
    } else if (allarmi?.type === "Equality") {
      const config = allarmi as EqualityMappaturaAllarmi;
      const message = config.mappings?.find(
        (m) => m.value === dispositivo.lastAlarmStatus
      )?.message;
      return message ? [message] : [];
    } else {
      // non conosciamo questa mappatura, facciamo fallback sul codice inviato dal dispositivo
      return [dispositivo.lastAlarmStatus!];
    }
  } else {
    if (hasAlarmStatus) {
      // siamo in stato di allarme, ma non c'è un alarm code
      return ["Allarme sconosciuto"];
    } else {
      // nessun allarme
      return [];
    }
  }
}
