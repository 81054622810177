import Box from "@/elements/Box";
import Button from "@/elements/Button";
import Typography from "@/elements/Typography";
import Card from "@mui/material/Card";
import PosizioneDispositivo from "../PosizioneDispositivo";
import ProprietaMisurataBar from "../ProprietaMisurataBar";
import {
  DispositivoStatus,
  getDispositivoTypeLabel,
} from "@/utils/dispositivoUtils";
import {
  DispositivoDto,
  getGetDispositiviQueryKey,
  ProprietaMisurataDto,
  useEliminaDispositivo,
} from "@/api";
import { Link } from "react-router-dom";
import { useAuth } from "@/context/useAuth";
import { useConfirmationDialog } from "@/utils/useConfirmationDialog";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { Grid, Icon, Tooltip } from "@mui/material";
import {
  classeDiContaminazione,
  splitProprietaStandardISO,
} from "@/utils/proprietaMisurataUtils";
import ProprietaMisurataLabel from "../ProprietaMisurataLabel";
import { DispositivoColor } from "../DispositivoDetails";

function DispositivoCard({
  dispositivo,
  proprietaMisurate,
  showPosizione,
}: {
  dispositivo: DispositivoDto;
  proprietaMisurate: ProprietaMisurataDto[];
  showPosizione: boolean;
}) {
  const { hasPermission } = useAuth();
  const { askConfirmation, confirmationDialog } = useConfirmationDialog();

  const { mutateAsync } = useEliminaDispositivo({
    mutation: {
      onSuccess: () => {
        toast.success("Dispositivo eliminato!");
      },
      onError: () => {
        toast.error("Errore nella richiesta");
      },
    },
  });

  const queryClient = useQueryClient();

  const deleteDispositivo = useCallback(
    async (dispositivo: DispositivoDto) => {
      try {
        await mutateAsync({ id: dispositivo.id });
        queryClient.invalidateQueries({
          queryKey: getGetDispositiviQueryKey(),
        });
      } catch (err) {}
    },
    [mutateAsync, queryClient]
  );

  const { proprietaStandardMisurate, proprietaISOMisurate } = useMemo(() => {
    return splitProprietaStandardISO(proprietaMisurate, 0);
  }, [proprietaMisurate]);

  const classeContaminazione = useMemo(() => {
    return classeDiContaminazione(proprietaISOMisurate);
  }, [proprietaISOMisurate]);

  const statusIcon = useMemo(() => {
    const status: DispositivoStatus =
      (dispositivo.lastStatus as DispositivoStatus) ?? "off";
    switch (status) {
      case "on":
        return (
          <Tooltip title="ON">
            <Icon color="success">cloud_queue</Icon>
          </Tooltip>
        );

      case "on-manuale":
        return (
          <Tooltip title="ON (manuale)">
            <Icon color="success">cloud_queue</Icon>
          </Tooltip>
        );

      case "stop":
        return (
          <Tooltip title="Connesso - OFF">
            <Icon color="error">power_settings_new</Icon>
          </Tooltip>
        );

      case "alarm":
        return (
          <Tooltip title="Allarme">
            <Icon color="warning">warning</Icon>
          </Tooltip>
        );

      case "off":
        return (
          <Tooltip title="Dispositivo non connesso">
            <Icon color="error">cloud_off</Icon>
          </Tooltip>
        );
    }
  }, [dispositivo]);

  const { posizione } = dispositivo;

  return (
    <Card sx={{ width: 410, height: 408 }}>
      {confirmationDialog}
      <Box p={2} flex={1}>
        <Box display="flex" flexDirection="column" height="100%">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography variant="body2" color="text" fontWeight="medium">
                {dispositivo.modello ??
                  getDispositivoTypeLabel(dispositivo.type)}
              </Typography>

              <Typography variant="h5" fontWeight="bold">
                {dispositivo.matricola}
              </Typography>
            </Box>

            <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
              {statusIcon}
              {dispositivo.lastStatus !== "off" && (
                <DispositivoColor dispositivo={dispositivo} />
              )}
            </Box>
          </Box>
          {showPosizione && (
            <Box sx={{ minHeight: 18 }}>
              {posizione && <PosizioneDispositivo posizione={posizione} />}
            </Box>
          )}
          {dispositivo.posizione?.titolare && (
            <Typography variant="caption" fontWeight="medium">
              Titolare: <b>{dispositivo.posizione.titolare.nome}</b>
            </Typography>
          )}
          {dispositivo.posizione?.modalita && (
            <Typography variant="caption" fontWeight="medium">
              Modalità: <b>{dispositivo.posizione?.modalita}</b>
            </Typography>
          )}

          {classeContaminazione && (
            <Typography variant="caption" fontWeight="medium" mt={1}>
              Classe di contaminazione: <b>{classeContaminazione}</b>
            </Typography>
          )}
          <Box mt={2} mb={0} alignContent="left">
            <Grid
              container
              rowSpacing={2} // Adjust spacing between grid items
              columnSpacing={1} // Adjust spacing between grid items
              sx={{
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              {proprietaStandardMisurate
                .filter((p) => p.type !== "unknown")
                .concat(proprietaISOMisurate)
                .map((proprietaMisurata, index) => (
                  <Grid item xs={4} key={index}>
                    {/* Adjusted to fit max 2 elements per row */}
                    <ProprietaMisurataLabel
                      proprietaMisurata={proprietaMisurata}
                      value={proprietaMisurata.lastValue || null}
                      typographyVariantPrimary="h4"
                      typographyVariantSecondary="caption"
                      alignItems="flex-start"
                    />
                  </Grid>
                ))}
            </Grid>
          </Box>
          <Box
            mt="auto"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              variant="textArrow"
              color="text"
              LinkComponent={Link}
              to={`/dispositivo/${dispositivo.id}`}
            >
              Vedi dettaglio
            </Button>

            {hasPermission("DispositivoDelete") && (
              <Button
                variant="text"
                size="small"
                color="error"
                startIcon={<Icon>delete_outlined</Icon>}
                sx={{ pb: 0 }}
                onClick={() => {
                  askConfirmation({
                    title: "Elimina dispositivo",
                    message: `Sei sicuro di voler eliminare il dispositivo ${dispositivo.matricola} (${dispositivo.modello})?`,
                    okColor: "error",
                    onConfirm: () => {
                      return deleteDispositivo(dispositivo);
                    },
                  });
                }}
              >
                Elimina
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

export default DispositivoCard;
