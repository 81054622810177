/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Gatti Filtrazioni
 * OpenAPI spec version: v1
 */
import axios from "axios";
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type { ModelloDispositivoDto } from ".././models";

export const getModelliDispositivo = (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<ModelloDispositivoDto[]>> => {
  return axios.get(`/api/modelli-dispositivo`, options);
};

export const getGetModelliDispositivoQueryKey = () =>
  [`/api/modelli-dispositivo`] as const;

export const getGetModelliDispositivoQueryOptions = <
  TData = Awaited<ReturnType<typeof getModelliDispositivo>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getModelliDispositivo>>,
    TError,
    TData
  >;
  axios?: AxiosRequestConfig;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getModelliDispositivo>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetModelliDispositivoQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getModelliDispositivo>>
  > = ({ signal }) => getModelliDispositivo({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions };
};

export type GetModelliDispositivoQueryResult = NonNullable<
  Awaited<ReturnType<typeof getModelliDispositivo>>
>;
export type GetModelliDispositivoQueryError = AxiosError<unknown>;

export const useGetModelliDispositivo = <
  TData = Awaited<ReturnType<typeof getModelliDispositivo>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getModelliDispositivo>>,
    TError,
    TData
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetModelliDispositivoQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const __$$useGetModelliDispositivo = () =>
  //@ts-ignore
  useGetModelliDispositivo(...([] as any[]));
