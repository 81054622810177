import Box from "@/elements/Box";
import Button from "@/elements/Button";
import Typography from "@/elements/Typography";
import Card from "@mui/material/Card";
import PosizioneDispositivo from "../PosizioneDispositivo";
import {
  getAllarmiDispositivo,
  getDispositivoTypeLabel,
} from "@/utils/dispositivoUtils";
import { Grid, Tooltip } from "@mui/material";
import { temp } from "@/utils/dispositiviImages";
import DispositivoStatus from "../DispositivoStatus";
import ProprietaMisurataCard from "../ProprietaMisurataCard";
import {
  DispositivoDto,
  ProprietaMisurataDto,
  getGetDispositivoQueryKey,
  useActionDispositivo,
  useGetDispositivo,
  useGetModelliDispositivo,
} from "@/api";
import Loading, { SmallLoading } from "../Loading";
import LoadingError from "../LoadingError";
import { useParams } from "react-router-dom";
import { useMemo, useState } from "react";
import ProprietaMisurataHistoryDialog from "../ProprietaMisurataHistoryDialog";
import TrasferimentiDialog from "../TrasferimentiDialog";
import { useAuth } from "@/context/useAuth";
import ImpostazioniNotificheDialog from "../ImpostazioniNotificheDialog";
import RegistriDispositivoDialog from "../RegistriDispositivoDialog";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import ManutenzioniTableCard from "./ManutenzioniTable";
import { immaginiByModello } from "@/utils/modelliDispositivi";
import ProprietaISOMisurateCard from "../ProprietaISOMisurateCard";
import { splitProprietaStandardISO } from "@/utils/proprietaMisurataUtils";
import DispositivoCSVDialog from "../DispositivoCSVDialog";

type DispositivoAction =
  | "start"
  | "start-manuale"
  | "stop"
  | "stop-manuale"
  | "reset";

function DispositivoDetails({
  dispositivo,
  proprietaMisurate,
}: {
  dispositivo: DispositivoDto;
  proprietaMisurate: ProprietaMisurataDto[];
}) {
  const { hasPermission } = useAuth();

  const modelliDispositivo = useGetModelliDispositivo();

  const modelloObj = useMemo(() => {
    if (!modelliDispositivo.data?.data) {
      return null;
    }

    const modello = modelliDispositivo.data.data.find(
      (m) => m.modello === dispositivo.modello
    );

    return modello ?? null;
  }, [modelliDispositivo.data, dispositivo]);

  const allarmiDispositivo = useMemo(() => {
    return getAllarmiDispositivo(modelloObj, dispositivo);
  }, [modelloObj, dispositivo]);

  const typeLabel = getDispositivoTypeLabel(dispositivo.type);

  const { posizione, matricola, modello } = dispositivo;

  const dispositiviImagePlaceholder = temp;

  const pathImmagineDispositivo = useMemo(() => {
    // @ts-ignore
    return immaginiByModello[dispositivo.modello];
  }, [dispositivo]);

  const { proprietaStandardMisurate, proprietaISOMisurate } = useMemo(() => {
    return splitProprietaStandardISO(proprietaMisurate);
  }, [proprietaMisurate]);

  const powerStatus =
    !!dispositivo.lastStatus &&
    ["on", "on-manuale", "alarm"].includes(dispositivo.lastStatus);
  const manuale =
    dispositivo.lastStatus === "on-manuale" ||
    dispositivo.lastStatus === "off-manuale";
  const connectionStatus =
    !!dispositivo.lastStatus && dispositivo.lastStatus !== "off";
  const canReset = true;
  const contaOreString = dispositivo.lastInfo?.contaore ?? " - ";

  const rightsideControls = (
    <Box display="flex" flexDirection="column" gap={2} ml={4}>
      <Button
        size="small"
        variant="contained"
        color="light"
        onClick={() => setTrasferimentiDialogOpen(true)}
      >
        Trasferimenti
      </Button>

      <Button
        size="small"
        variant="contained"
        color="light"
        onClick={() => setCSVDialogOpen(true)}
      >
        Scarica dati grezzi
      </Button>

      {hasPermission("DispositivoRegistriRead") && (
        <Button
          size="small"
          variant="contained"
          color="light"
          disabled={!connectionStatus}
          onClick={() => setModificaRegistriDialogOpen(true)}
        >
          Modifica registri
        </Button>
      )}

      <Box />

      {/* <Box display="flex" py={1} mt={3} alignItems="center">
        <Typography variant="button" fontWeight="regular" color="text" mr={2}>
          Notifiche:
        </Typography>
        <Switch checked={true} color="success" />
      </Box> */}

      <Button
        size="small"
        variant="contained"
        color="light"
        onClick={() => setImpostazioniNotificheDialogOpen(true)}
      >
        Impostazioni notifiche
      </Button>
    </Box>
  );

  const { mutateAsync: setActionAsync, isLoading: actionLoading } =
    useActionDispositivo();

  const [sentAction, setSentAction] = useState<DispositivoAction | null>(null);

  const queryClient = useQueryClient();

  async function setAction(action: DispositivoAction) {
    try {
      setSentAction(action);
      const response = await setActionAsync({
        id: dispositivo.id,
        data: { action: action },
      });
      if (response.data?.updated) {
        toast.success("Stato del macchinario cambiato con successo!");
      } else {
        toast.warn(
          "Il cambio di stato è stato inviato al PLC, ma non è stata ricevuta alcuna risposta"
        );
      }
    } catch (e) {
      toast.error("Errore durante il cambiamento di stato del macchinario");
    } finally {
      setSentAction(null);
      queryClient.invalidateQueries({
        queryKey: getGetDispositivoQueryKey(dispositivo.id),
      });
    }
  }

  const content = (
    <Box flex={1}>
      <Box display="flex" flexDirection="row" alignItems="baseline" gap={2}>
        <Typography variant="body2" color="text" fontWeight="medium">
          {typeLabel}
        </Typography>

        <Typography variant="h5" fontWeight="bold">
          {dispositivo.matricola}
        </Typography>
      </Box>

      {posizione && (
        <Box>
          <PosizioneDispositivo posizione={posizione} showDate />
        </Box>
      )}

      {posizione?.titolare && (
        <Box>
          <Typography variant="caption" fontWeight="medium">
            Titolare: <b>{posizione.titolare.nome}</b>
          </Typography>
        </Box>
      )}
      {posizione?.modalita && (
        <Box>
          <Typography variant="caption" fontWeight="medium">
            Modalità: <b>{posizione?.modalita}</b>
          </Typography>
        </Box>
      )}

      <Box
        display="flex"
        flexDirection="row"
        gap={2}
        sx={{
          containerType: "inline-size",
        }}
      >
        <Box
          p={2}
          sx={{
            maxWidth: 300,
            "& > svg": {
              width: "100%",
            },
            "@container (max-width: 450px)": {
              display: "none",
            },
            "@container (min-width: 650px)": {
              marginRight: "50px",
            },
          }}
        >
          {pathImmagineDispositivo ? (
            <img
              width="100%"
              src={pathImmagineDispositivo}
              alt="Immagine dispositivo"
            />
          ) : (
            dispositiviImagePlaceholder()
          )}
        </Box>

        <Box display="flex" flexDirection="column" gap={2} pt={4}>
          <DispositivoStatus
            type="connection"
            status={connectionStatus}
            controls={
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ height: "100%" }}
              >
                {dispositivo.lastStatus !== "off" && (
                  <DispositivoColor dispositivo={dispositivo} />
                )}
              </Box>
            }
          />

          {connectionStatus && (
            <DispositivoStatus
              type="power"
              status={powerStatus}
              manuale={manuale}
              alarm={dispositivo.lastStatus === "alarm"}
              controls={
                <Box display="flex" flexDirection="column" gap={1}>
                  {dispositivo.type === "macchinario" && (
                    <>
                      {powerStatus && manuale && (
                        <Button
                          size="small"
                          variant="contained"
                          color={actionLoading ? undefined : "light"}
                          disabled={actionLoading}
                          onClick={() => {
                            setAction("stop-manuale").then();
                          }}
                          startIcon={
                            sentAction === "stop-manuale" && (
                              <SmallLoading sx={{ mr: 1 }} />
                            )
                          }
                        >
                          Stop (manuale)
                        </Button>
                      )}
                      {powerStatus && !manuale && (
                        <Button
                          size="small"
                          variant="contained"
                          color={actionLoading ? undefined : "light"}
                          disabled={actionLoading}
                          onClick={() => {
                            setAction("stop").then();
                          }}
                          startIcon={
                            sentAction === "stop" && (
                              <SmallLoading sx={{ mr: 1 }} />
                            )
                          }
                        >
                          Stop
                        </Button>
                      )}
                      {!powerStatus && (
                        <Button
                          size="small"
                          variant="contained"
                          color={actionLoading ? undefined : "light"}
                          disabled={actionLoading}
                          onClick={() => {
                            setAction("start").then();
                          }}
                          startIcon={
                            sentAction === "start" && (
                              <SmallLoading sx={{ mr: 1 }} />
                            )
                          }
                        >
                          Start
                        </Button>
                      )}
                      {!powerStatus && (
                        <Button
                          size="small"
                          variant="contained"
                          color={actionLoading ? undefined : "light"}
                          disabled={actionLoading}
                          onClick={() => {
                            setAction("start-manuale").then();
                          }}
                          startIcon={
                            sentAction === "start-manuale" && (
                              <SmallLoading sx={{ mr: 1 }} />
                            )
                          }
                        >
                          Start (manuale)
                        </Button>
                      )}
                    </>
                  )}

                  {canReset && (
                    <Button
                      size="small"
                      variant="contained"
                      color={actionLoading ? undefined : "light"}
                      disabled={actionLoading}
                      onClick={() => {
                        setAction("reset").then();
                      }}
                      startIcon={
                        sentAction === "reset" && (
                          <SmallLoading sx={{ mr: 1 }} />
                        )
                      }
                    >
                      Reset
                    </Button>
                  )}
                </Box>
              }
            />
          )}

          {dispositivo.type === "macchinario" && (
            <Box>
              <Typography variant="caption">Conta ore:</Typography>
              <Typography variant="h4">{contaOreString}</Typography>
            </Box>
          )}

          {allarmiDispositivo && allarmiDispositivo.length > 0 && (
            <Box>
              <Typography variant="caption" color="error">
                {allarmiDispositivo.length === 1 ? "Allarme: " : "Allarmi: "}
              </Typography>
              {allarmiDispositivo.map((allarme) => {
                return (
                  <Typography variant="h6" color="error" key={allarme}>
                    {allarme}
                  </Typography>
                );
              })}
            </Box>
          )}

          <Box gap={1}>
            <Box>
              <Typography variant="caption">Modello:</Typography>
              <Typography variant="h6">{modello}</Typography>
            </Box>
            <Box>
              <Typography variant="caption">Matricola:</Typography>
              <Typography variant="h6">{matricola}</Typography>
            </Box>

            {dispositivo.type === "sensore" &&
              dispositivo.lastInfo &&
              dispositivo.lastInfo.fwVersion && (
                <Box>
                  <Typography variant="caption">Versione Firmware:</Typography>
                  <Typography variant="h6">
                    {dispositivo.lastInfo.fwVersion}
                  </Typography>
                </Box>
              )}
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const [historyDialogOptions, setHistoryDialogOptions] = useState<{
    proprietaMisurata: ProprietaMisurataDto;
  } | null>(null);

  const [trasferimentiDialogOpen, setTrasferimentiDialogOpen] = useState(false);
  const [impostazioniNotificheDialogOpen, setImpostazioniNotificheDialogOpen] =
    useState(false);
  const [modificaRegistriDialogOpen, setModificaRegistriDialogOpen] =
    useState(false);
  const [csvDialogOpen, setCSVDialogOpen] = useState(false);

  function openHistoryDialog(proprietaMisurata: ProprietaMisurataDto): void {
    setHistoryDialogOptions({ proprietaMisurata });
  }

  return (
    <Box p={2} display="flex" flexDirection="column" gap={2}>
      <Grid container>
        <Grid item md={12}>
          <Card>
            <Box p={2}>
              <Box display="flex" flexDirection="row" alignItems="flex-start">
                {content}
                {rightsideControls}
              </Box>
            </Box>
          </Card>
        </Grid>

        <Grid item md={12}>
          <Box
            mt={2}
            mb={0}
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            gap={2}
          >
            {proprietaStandardMisurate.map((proprietaMisurata, index) => {
              return (
                <ProprietaMisurataCard
                  key={index}
                  proprietaMisurata={proprietaMisurata}
                  value={proprietaMisurata.lastValue ?? null}
                  openHistoryDialog={() => openHistoryDialog(proprietaMisurata)}
                />
              );
            })}
            {proprietaISOMisurate.length > 0 && (
              <ProprietaISOMisurateCard
                proprietaMisurate={proprietaISOMisurate}
              />
            )}
          </Box>
        </Grid>
      </Grid>

      <ProprietaMisurataHistoryDialog
        open={!!historyDialogOptions}
        dispositivo={dispositivo}
        proprietaMisurata={historyDialogOptions?.proprietaMisurata}
        onClose={() => setHistoryDialogOptions(null)}
      />
      <TrasferimentiDialog
        open={trasferimentiDialogOpen}
        dispositivo={dispositivo}
        trasferimenti={dispositivo.trasferimenti ?? undefined}
        onClose={() => setTrasferimentiDialogOpen(false)}
        canEdit={hasPermission("TrasferimentiChange")}
      />
      <ImpostazioniNotificheDialog
        open={impostazioniNotificheDialogOpen}
        dispositivo={dispositivo}
        proprietaMisurate={dispositivo.proprietaMisurate ?? undefined}
        onClose={() => setImpostazioniNotificheDialogOpen(false)}
      />
      <RegistriDispositivoDialog
        open={modificaRegistriDialogOpen}
        dispositivo={dispositivo}
        onClose={() => setModificaRegistriDialogOpen(false)}
      />
      <DispositivoCSVDialog
        open={csvDialogOpen}
        onClose={() => setCSVDialogOpen(false)}
        dispositivo={dispositivo}
      />
      {hasPermission("DispositiviReadManutenzioni") && (
        <Grid xs={12}>
          <ManutenzioniTableCard
            dispositivo={dispositivo}
            canEdit={hasPermission("DispositiviUpdateManutenzioni")}
          />
        </Grid>
      )}
    </Box>
  );
}

export default DispositivoDetails;

export function DispositivoDetailsPage() {
  const params = useParams();
  const id = parseInt(params.id || "");

  const { isLoading, error, data } = useGetDispositivo(id, {
    query: { refetchInterval: 15_000 },
  });

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <LoadingError error={error} />;
  }

  const dispositivo = data.data;

  return (
    <DispositivoDetails
      dispositivo={dispositivo}
      proprietaMisurate={dispositivo.proprietaMisurate || []}
    />
  );
}

export function DispositivoColor({
  dispositivo,
}: {
  dispositivo: DispositivoDto;
}) {
  if (dispositivo.lastColor === null) {
    return null;
  }

  const lastColor = dispositivo.lastColor;
  const color =
    lastColor === 1
      ? "#007A33" // verde
      : lastColor === 2
      ? "#1E90FF" // blu
      : lastColor === 3
      ? "#FFB400" // giallo
      : lastColor === 4
      ? "#D32F2F" // rosso
      : null;

  if (!color) {
    return null;
  }

  return (
    <Tooltip title="Colore LED">
      <Box
        sx={{
          width: 20,
          height: 20,
          borderRadius: "50%",
          backgroundColor: color,
        }}
      />
    </Tooltip>
  );
}
