import Box from "@/elements/Box";
import NumberInput from "@/elements/Input/NumberInput";
import {
  FormHelperText,
  Grid,
  InputAdornment,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import Card from "@mui/material/Card";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
} from "react-hook-form";
import TextField from "@mui/material/TextField";
import { RegistroDto, UpdateRegistroDto } from "@/api";
import { isNil, keyBy } from "lodash";

export type RegistriGFLTFL700 = {
  SPriemp?: number;
  SPprecic?: number;
  SPtempo1?: number; // int
  SPtempo2?: number; // int
  SPout?: number; // int
  SPBatteria?: boolean;
  "SP-t-off"?: number; // int
  "SP-ciclo"?: number;
  "SP-cicloout"?: number; // int
  passfiltro?: boolean;
  allbassaT?: number;
  allaltaT?: number;
  allPmin?: number;
  allPminT?: number;
  email1?: string;
  email2?: string;
  funzman?: boolean;
  funzmanpower?: number;
};

export function registriGFLTFL700ToForm(_registri: RegistroDto[]): {
  [r in keyof RegistriGFLTFL700]: any;
} {
  const registri = keyBy(_registri, "registro");

  return {
    SPriemp: isNil(registri["SPriemp"])
      ? undefined
      : parseFloat(registri["SPriemp"].value),
    SPprecic: isNil(registri["SPprecic"])
      ? undefined
      : parseFloat(registri["SPprecic"].value),
    SPtempo1: isNil(registri["SPtempo1"])
      ? undefined
      : parseFloat(registri["SPtempo1"].value) / 1000,
    SPtempo2: isNil(registri["SPtempo2"])
      ? undefined
      : parseFloat(registri["SPtempo2"].value) / 1000,
    SPout: isNil(registri["SPout"])
      ? undefined
      : parseFloat(registri["SPout"].value),
    SPBatteria: isNil(registri["SPBatteria"])
      ? undefined
      : registri["SPBatteria"].value === "true",
    "SP-t-off": isNil(registri["SP-t-off"])
      ? undefined
      : parseFloat(registri["SP-t-off"].value) / 1000,
    "SP-ciclo": isNil(registri["SP-ciclo"])
      ? undefined
      : parseFloat(registri["SP-ciclo"].value),
    "SP-cicloout": isNil(registri["SP-cicloout"])
      ? undefined
      : parseFloat(registri["SP-cicloout"].value),
    passfiltro: isNil(registri["passfiltro"])
      ? undefined
      : registri["passfiltro"].value === "true",
    allbassaT: isNil(registri["allbassaT"])
      ? undefined
      : parseFloat(registri["allbassaT"].value),
    allaltaT: isNil(registri["allaltaT"])
      ? undefined
      : parseFloat(registri["allaltaT"].value),
    allPmin: isNil(registri["allPmin"])
      ? undefined
      : parseFloat(registri["allPmin"].value),
    allPminT: isNil(registri["allPminT"])
      ? undefined
      : parseFloat(registri["allPminT"].value),
    email1: isNil(registri["email1"]) ? undefined : registri["email1"].value,
    email2: isNil(registri["email2"]) ? undefined : registri["email2"].value,
    funzman: isNil(registri["funzman"])
      ? undefined
      : registri["funzman"].value === "true",
    funzmanpower: isNil(registri["funzmanpower"])
      ? undefined
      : parseFloat(registri["funzmanpower"].value),
  };
}

export function formGFLTFL700ToRegistri(
  form: RegistriGFLTFL700
): UpdateRegistroDto[] {
  const topicPrefix = "var";
  const _values: { [k in keyof RegistriGFLTFL700]?: UpdateRegistroDto } = {
    SPriemp: isNil(form.SPriemp)
      ? undefined
      : { registro: "SPriemp", topicPrefix, value: formatFloat(form.SPriemp) },
    SPprecic: isNil(form.SPprecic)
      ? undefined
      : {
          registro: "SPprecic",
          topicPrefix,
          value: formatFloat(form.SPprecic),
        },
    SPtempo1: isNil(form.SPtempo1)
      ? undefined
      : {
          registro: "SPtempo1",
          topicPrefix,
          value: formatInt(form.SPtempo1 * 1000),
        },
    SPtempo2: isNil(form.SPtempo2)
      ? undefined
      : {
          registro: "SPtempo2",
          topicPrefix,
          value: formatInt(form.SPtempo2 * 1000),
        },
    // read-only
    // SPout: isNil(form.SPout)
    //   ? undefined
    //   : { registro: "SPout", topicPrefix, value: formatInt(form.SPout) },
    SPBatteria: isNil(form.SPBatteria)
      ? undefined
      : { registro: "SPBatteria", topicPrefix, value: `${form.SPBatteria}` },
    "SP-t-off": isNil(form["SP-t-off"])
      ? undefined
      : {
          registro: "SP-t-off",
          topicPrefix,
          value: formatInt(form["SP-t-off"] * 1000),
        },
    "SP-ciclo": isNil(form["SP-ciclo"])
      ? undefined
      : {
          registro: "SP-ciclo",
          topicPrefix,
          value: formatFloat(form["SP-ciclo"]),
        },
    // read-only
    // "SP-cicloout": isNil(form["SP-cicloout"])
    //   ? undefined
    //   : {
    //       registro: "SP-cicloout",
    //       topicPrefix,
    //       value: formatInt(form["SP-cicloout"]),
    //     },
    passfiltro: isNil(form["passfiltro"])
      ? undefined
      : { registro: "passfiltro", topicPrefix, value: `${form["passfiltro"]}` },
    allbassaT: isNil(form["allbassaT"])
      ? undefined
      : {
          registro: "allbassaT",
          topicPrefix,
          value: formatFloat(form["allbassaT"]),
        },
    allaltaT: isNil(form["allaltaT"])
      ? undefined
      : {
          registro: "allaltaT",
          topicPrefix,
          value: formatFloat(form["allaltaT"]),
        },
    allPmin: isNil(form["allPmin"])
      ? undefined
      : {
          registro: "allPmin",
          topicPrefix,
          value: formatFloat(form["allPmin"]),
        },
    allPminT: isNil(form["allPminT"])
      ? undefined
      : {
          registro: "allPminT",
          topicPrefix,
          value: formatInt(form["allPminT"]),
        },
    email1: isNil(form["email1"])
      ? undefined
      : {
          registro: "email1",
          topicPrefix,
          value: mapEmptyStringToSlash(form["email1"]),
        },
    email2: isNil(form["email2"])
      ? undefined
      : {
          registro: "email2",
          topicPrefix,
          value: mapEmptyStringToSlash(form["email2"]),
        },
    funzman: isNil(form["funzman"])
      ? undefined
      : { registro: "funzman", topicPrefix, value: `${form["funzman"]}` },
    funzmanpower: isNil(form["funzmanpower"])
      ? undefined
      : {
          registro: "funzmanpower",
          topicPrefix,
          value: formatInt(form["funzmanpower"]),
        },
  };
  return Object.values(_values);
}

function mapEmptyStringToSlash(value: string) {
  if (value === "") {
    return "/";
  }
  return value;
}

function formatFloat(value: number) {
  const formattedNum = value.toExponential(6).replace("e", "E");
  return formattedNum;
}

function formatInt(value: number) {
  return Math.floor(value).toString();
}

export function EditorRegistriGFLTFL700({
  control,
  errors,
  register,
  readOnly = false,
}: {
  control: Control<RegistriGFLTFL700>;
  errors: FieldErrors<RegistriGFLTFL700>;
  register: UseFormRegister<RegistriGFLTFL700>;
  readOnly?: boolean;
}) {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Card>
        <Box p={2} flex={1}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography
              variant="h5"
              color="text"
              fontWeight="medium"
              gutterBottom
            >
              SP PID PRE-CICLO
            </Typography>

            <Grid container rowSpacing={1} columnSpacing={2}>
              <Grid item sm={12} md={6}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    mr={2}
                  >
                    SP riempimento
                  </Typography>
                  <Controller
                    control={control}
                    name="SPriemp"
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <Stack direction="column" alignItems="end">
                          <NumberInput
                            {...field}
                            sx={{ maxWidth: 100 }}
                            disabled={readOnly}
                          />
                          {error && (
                            <FormHelperText error>
                              {error?.message}
                            </FormHelperText>
                          )}
                        </Stack>
                      );
                    }}
                  />
                </Box>
              </Grid>

              <Grid item sm={12} md={6}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    mr={2}
                  >
                    Tempo
                  </Typography>
                  <Controller
                    control={control}
                    name="SPtempo1"
                    render={({ field, fieldState: { error } }) => (
                      <Stack direction="column" alignItems="end">
                        <NumberInput
                          {...field}
                          sx={{ maxWidth: 100 }}
                          endAdornment={
                            <InputAdornment position="end">s</InputAdornment>
                          }
                          disabled={readOnly}
                        />
                        {error && (
                          <FormHelperText error>
                            {error?.message}
                          </FormHelperText>
                        )}
                      </Stack>
                    )}
                  />
                </Box>
              </Grid>

              <Grid item sm={12} md={6}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    mr={2}
                  >
                    SP pre-ciclo
                  </Typography>
                  <Controller
                    control={control}
                    name="SPprecic"
                    render={({ field, fieldState: { error } }) => (
                      <Stack direction="column" alignItems="end">
                        <NumberInput
                          {...field}
                          sx={{ maxWidth: 100 }}
                          disabled={readOnly}
                        />
                        {error && (
                          <FormHelperText error>
                            {error?.message}
                          </FormHelperText>
                        )}
                      </Stack>
                    )}
                  />
                </Box>
              </Grid>

              <Grid item sm={12} md={6}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    mr={2}
                  >
                    Tempo
                  </Typography>
                  <Controller
                    control={control}
                    name="SPtempo2"
                    render={({ field, fieldState: { error } }) => (
                      <Stack direction="column" alignItems="end">
                        <NumberInput
                          {...field}
                          sx={{ maxWidth: 100 }}
                          endAdornment={
                            <InputAdornment position="end">s</InputAdornment>
                          }
                          disabled={readOnly}
                        />
                        {error && (
                          <FormHelperText error>
                            {error?.message}
                          </FormHelperText>
                        )}
                      </Stack>
                    )}
                  />
                </Box>
              </Grid>

              <Grid item sm={12} md={6}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    mr={2}
                  >
                    SP Out
                  </Typography>
                  <Controller
                    control={control}
                    name="SPout"
                    render={({ field, fieldState: { error } }) => (
                      <Stack direction="column" alignItems="end">
                        <NumberInput
                          {...field}
                          sx={{ maxWidth: 100 }}
                          numericInputProps={{ decimalScale: 0 }}
                          disabled
                        />
                        {error && (
                          <FormHelperText error>
                            {error?.message}
                          </FormHelperText>
                        )}
                      </Stack>
                    )}
                  />
                </Box>
              </Grid>
              <Grid item sm={12} md={6} />

              {/* <Grid item sm={12} md={6}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    mr={2}
                  >
                    G
                  </Typography>
                  <NumberInput sx={{ maxWidth: 100 }} disabled={readOnly} />
                </Box>
              </Grid>
              <Grid item sm={12} md={6} />

              <Grid item sm={12} md={6}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    mr={2}
                  >
                    TD
                  </Typography>
                  <NumberInput sx={{ maxWidth: 100 }} disabled={readOnly} />
                </Box>
              </Grid>
              <Grid item sm={12} md={6} />

              <Grid item sm={12} md={6}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    mr={2}
                  >
                    TI
                  </Typography>
                  <NumberInput sx={{ maxWidth: 100 }} disabled={readOnly} />
                </Box>
              </Grid>
              <Grid item sm={12} md={6} /> */}

              <Grid item sm={12} md={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    mr={2}
                  >
                    T spegnimento
                  </Typography>
                  <Controller
                    control={control}
                    name="SP-t-off"
                    render={({ field, fieldState: { error } }) => (
                      <Stack direction="column" alignItems="end">
                        <NumberInput
                          {...field}
                          sx={{ maxWidth: 100 }}
                          endAdornment={
                            <InputAdornment position="end">s</InputAdornment>
                          }
                          numericInputProps={{ decimalScale: 0 }}
                          disabled={readOnly}
                        />
                        {error && (
                          <FormHelperText error>
                            {error?.message}
                          </FormHelperText>
                        )}
                      </Stack>
                    )}
                  />
                </Box>
              </Grid>
              <Grid item sm={12} md={6} />

              <Grid item sm={12} md={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    mr={2}
                  >
                    Bypass batteria
                  </Typography>
                  <Controller
                    control={control}
                    name="SPBatteria"
                    render={({ field, fieldState: { error } }) => (
                      <Stack direction="column" alignItems="end">
                        <Switch
                          color="success"
                          {...field}
                          checked={field.value}
                          disabled={readOnly}
                        />
                        {error && (
                          <FormHelperText error>
                            {error?.message}
                          </FormHelperText>
                        )}
                      </Stack>
                    )}
                  />
                </Box>
              </Grid>
              <Grid item sm={12} md={6} />
            </Grid>
          </Box>
        </Box>
      </Card>

      <Card>
        <Box p={2} flex={1}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography
              variant="h5"
              color="text"
              fontWeight="medium"
              gutterBottom
            >
              SP PID CICLO
            </Typography>

            <Grid container rowSpacing={1} columnSpacing={2}>
              <Grid item sm={12} md={6}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    mr={2}
                  >
                    SP ciclo
                  </Typography>
                  <Controller
                    control={control}
                    name="SP-ciclo"
                    render={({ field, fieldState: { error } }) => (
                      <Stack direction="column" alignItems="end">
                        <NumberInput
                          {...field}
                          sx={{ maxWidth: 100 }}
                          disabled={readOnly}
                        />
                        {error && (
                          <FormHelperText error>
                            {error?.message}
                          </FormHelperText>
                        )}
                      </Stack>
                    )}
                  />
                </Box>
              </Grid>

              <Grid item sm={12} md={6}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    mr={2}
                  >
                    SP ciclo out
                  </Typography>
                  <Controller
                    control={control}
                    name="SP-cicloout"
                    render={({ field, fieldState: { error } }) => (
                      <Stack direction="column" alignItems="end">
                        <NumberInput
                          {...field}
                          sx={{ maxWidth: 100 }}
                          endAdornment={
                            <InputAdornment position="end">%</InputAdornment>
                          }
                          numericInputProps={{ decimalScale: 0 }}
                          disabled
                        />
                        {error && (
                          <FormHelperText error>
                            {error?.message}
                          </FormHelperText>
                        )}
                      </Stack>
                    )}
                  />
                </Box>
              </Grid>

              {/* <Grid item sm={12} md={6}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    mr={2}
                  >
                    G
                  </Typography>
                  <NumberInput sx={{ maxWidth: 100 }} disabled={readOnly}/>
                </Box>
              </Grid>
              <Grid item sm={12} md={6} />

              <Grid item sm={12} md={6}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    mr={2}
                  >
                    TD
                  </Typography>
                  <NumberInput sx={{ maxWidth: 100 }} disabled={readOnly}/>
                </Box>
              </Grid>
              <Grid item sm={12} md={6} />

              <Grid item sm={12} md={6}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    mr={2}
                  >
                    TI
                  </Typography>
                  <NumberInput sx={{ maxWidth: 100 }} disabled={readOnly} />
                </Box>
              </Grid>
              <Grid item sm={12} md={6} /> */}

              <Grid item sm={12} md={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    mr={2}
                  >
                    Bypass filtro
                  </Typography>
                  <Controller
                    control={control}
                    name="passfiltro"
                    render={({ field, fieldState: { error } }) => (
                      <Stack direction="column" alignItems="end">
                        <Switch
                          color="success"
                          {...field}
                          checked={field.value}
                          disabled={readOnly}
                        />
                        {error && (
                          <FormHelperText error>
                            {error?.message}
                          </FormHelperText>
                        )}
                      </Stack>
                    )}
                  />
                </Box>
              </Grid>
              <Grid item sm={12} md={6} />
            </Grid>
          </Box>
        </Box>
      </Card>

      <Card>
        <Box p={2} flex={1}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography
              variant="h5"
              color="text"
              fontWeight="medium"
              gutterBottom
            >
              Allarmi
            </Typography>
            <Grid container rowSpacing={1} columnSpacing={2}>
              <Grid item sm={12} md={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    mr={2}
                  >
                    Bassa temperatura
                  </Typography>
                  <Controller
                    control={control}
                    name="allbassaT"
                    render={({ field, fieldState: { error } }) => (
                      <Stack direction="column" alignItems="end">
                        <NumberInput
                          {...field}
                          sx={{ maxWidth: 100 }}
                          endAdornment={
                            <InputAdornment position="end">°C</InputAdornment>
                          }
                          disabled={readOnly}
                        />
                        {error && (
                          <FormHelperText error>
                            {error?.message}
                          </FormHelperText>
                        )}
                      </Stack>
                    )}
                  />
                </Box>
              </Grid>

              <Grid item sm={12} md={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    mr={2}
                  >
                    Alta temperatura
                  </Typography>
                  <Controller
                    control={control}
                    name="allaltaT"
                    render={({ field, fieldState: { error } }) => (
                      <Stack direction="column" alignItems="end">
                        <NumberInput
                          {...field}
                          sx={{ maxWidth: 100 }}
                          endAdornment={
                            <InputAdornment position="end">°C</InputAdornment>
                          }
                          disabled={readOnly}
                        />
                        {error && (
                          <FormHelperText error>
                            {error?.message}
                          </FormHelperText>
                        )}
                      </Stack>
                    )}
                  />
                </Box>
              </Grid>

              <Grid item sm={12} md={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    mr={2}
                  >
                    Pressione minima
                  </Typography>
                  <Controller
                    control={control}
                    name="allPmin"
                    render={({ field, fieldState: { error } }) => (
                      <Stack direction="column" alignItems="end">
                        <NumberInput
                          {...field}
                          sx={{ maxWidth: 100 }}
                          disabled={readOnly}
                        />
                        {error && (
                          <FormHelperText error>
                            {error?.message}
                          </FormHelperText>
                        )}
                      </Stack>
                    )}
                  />
                </Box>
              </Grid>
              <Grid item sm={12} md={6} />

              <Grid item sm={12} md={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    mr={2}
                  >
                    Pressione minima T
                  </Typography>
                  <Controller
                    control={control}
                    name="allPminT"
                    render={({ field, fieldState: { error } }) => (
                      <Stack direction="column" alignItems="end">
                        <NumberInput
                          {...field}
                          sx={{ maxWidth: 100 }}
                          disabled={readOnly}
                        />
                        {error && (
                          <FormHelperText error>
                            {error?.message}
                          </FormHelperText>
                        )}
                      </Stack>
                    )}
                  />
                </Box>
              </Grid>
              <Grid item sm={12} md={6} />
            </Grid>
          </Box>
        </Box>
      </Card>

      <Card>
        <Box p={2} flex={1}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography
              variant="h5"
              color="text"
              fontWeight="medium"
              gutterBottom
            >
              Email
            </Typography>
            <Grid container rowSpacing={1} columnSpacing={2}>
              <Grid item sm={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    mr={2}
                    sx={{ minWidth: 100 }}
                  >
                    Email 1
                  </Typography>
                  <TextField
                    {...register("email1")}
                    error={!!errors.email1?.message}
                    helperText={errors.email1?.message || null}
                    fullWidth
                    disabled={readOnly}
                  />
                </Box>
              </Grid>

              <Grid item sm={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    mr={2}
                    sx={{ minWidth: 100 }}
                  >
                    Email 2
                  </Typography>
                  <TextField
                    {...register("email2")}
                    error={!!errors.email2?.message}
                    helperText={errors.email2?.message || null}
                    fullWidth
                    disabled={readOnly}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Card>

      <Card>
        <Box p={2} flex={1}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography
              variant="h5"
              color="text"
              fontWeight="medium"
              gutterBottom
            >
              Funzionamento manuale
            </Typography>
            <Grid container rowSpacing={1} columnSpacing={2}>
              <Grid item sm={12} md={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    mr={2}
                  >
                    Funzionamento manuale
                  </Typography>
                  <Controller
                    control={control}
                    name="funzman"
                    render={({ field, fieldState: { error } }) => (
                      <Stack direction="column" alignItems="end">
                        <Switch
                          checked={field.value}
                          color="success"
                          {...field}
                          disabled={readOnly}
                        />
                        {error && (
                          <FormHelperText error>
                            {error?.message}
                          </FormHelperText>
                        )}
                      </Stack>
                    )}
                  />
                </Box>
              </Grid>

              <Grid item sm={12} md={6}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    mr={2}
                  >
                    Potenza motore
                  </Typography>
                  <Controller
                    control={control}
                    name="funzmanpower"
                    render={({ field, fieldState: { error } }) => (
                      <Stack direction="column" alignItems="end">
                        <NumberInput
                          {...field}
                          sx={{ maxWidth: 100 }}
                          endAdornment={
                            <InputAdornment position="end">%</InputAdornment>
                          }
                          disabled={readOnly}
                        />
                        {error && (
                          <FormHelperText error>
                            {error?.message}
                          </FormHelperText>
                        )}
                      </Stack>
                    )}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Card>
    </Box>
  );
}
