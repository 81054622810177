// *******************
// *******************
// FILE AUTO GENERATO! n// *******************
// *******************

export const _proprietaMisurataUtilsData = {
  Temperatura: {
    label: "Temperatura",
    labelWithArticle: "la temperatura",
    shortLabel: "Temp.",
    unit: "°C",
    unitPosition: "right",
    percentMin: 0.0,
    percentMax: 100.0,
  },
  Particelle: {
    label: "Numero di particelle (deprecato)",
    labelWithArticle: "il numero di particelle",
    shortLabel: "N° Part.",
    unit: null,
    unitPosition: null,
    percentMin: 0.0,
    percentMax: 100000.0,
  },
  Particelle_iso4: {
    label: "Numero di particelle (ISO 4)",
    labelWithArticle: "il numero di particelle",
    shortLabel: "ISO 4",
    unit: null,
    unitPosition: null,
    percentMin: 0.0,
    percentMax: 100000.0,
  },
  Particelle_iso6: {
    label: "Numero di particelle (ISO 6)",
    labelWithArticle: "il numero di particelle",
    shortLabel: "ISO 6",
    unit: null,
    unitPosition: null,
    percentMin: 0.0,
    percentMax: 100000.0,
  },
  Particelle_iso14: {
    label: "Numero di particelle (ISO 14)",
    labelWithArticle: "il numero di particelle",
    shortLabel: "ISO 14",
    unit: null,
    unitPosition: null,
    percentMin: 0.0,
    percentMax: 100000.0,
  },
  Particelle_iso21: {
    label: "Numero di particelle (ISO 21)",
    labelWithArticle: "il numero di particelle",
    shortLabel: "ISO 21",
    unit: null,
    unitPosition: null,
    percentMin: 0.0,
    percentMax: 100000.0,
  },
  Pressione: {
    label: "Pressione",
    labelWithArticle: "la pressione",
    shortLabel: "Pressione",
    unit: "bar",
    unitPosition: "right",
    percentMin: 0.0,
    percentMax: 10.0,
  },
  Umidita: {
    label: "Umidità",
    labelWithArticle: "l'umidità",
    shortLabel: "Umidità",
    unit: "%",
    unitPosition: "right",
    percentMin: 0.0,
    percentMax: 100.0,
  },
  Portata: {
    label: "Portata",
    labelWithArticle: "la portata",
    shortLabel: "Portata",
    unit: "l/h",
    unitPosition: "right",
    percentMin: 0.0,
    percentMax: 2400.0,
  },
  PotenzaMotore: {
    label: "Potenza motore",
    labelWithArticle: "la potenza motore",
    shortLabel: "Potenza",
    unit: "%",
    unitPosition: "right",
    percentMin: 0.0,
    percentMax: 100.0,
  },
  Saturazione: {
    label: "Saturazione",
    labelWithArticle: "la saturazione",
    shortLabel: "Saturazione",
    unit: "%",
    unitPosition: "right",
    percentMin: 0.0,
    percentMax: 100.0,
  },
} as const;
