import { useRef, useEffect, useState, useMemo } from "react";
import { Line } from "react-chartjs-2";
import gradientChartLine from "@/theme/functions/gradientChartLine";
import colors from "@/theme/base/colors";
import Box from "@/elements/Box";
import configs from "./configs";
import { ChartDataset, ScriptableLineSegmentContext } from "chart.js";
import {
  Chart as ChartJS,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  Filler,
  Tooltip,
} from "chart.js";
import moment from "moment";
import { ProprietaMisurataDto, ProprietaMisurataValueDto } from "@/api";
import { getProprietaMisurataHelper } from "@/utils/proprietaMisurataUtils";

ChartJS.register(
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  Filler,
  Tooltip
);

function ProprietaMisurataHistoryChart({
  proprietaMisurata,
  values,
  height,
}: {
  proprietaMisurata: ProprietaMisurataDto;
  values: ProprietaMisurataValueDto[];
  height?: number | null;
}) {
  const proprietaMisurataHelper = useMemo(() => {
    return getProprietaMisurataHelper(proprietaMisurata.type);
  }, [proprietaMisurata]);

  const chart = useMemo(() => {
    return {
      labels: values.map((x) => {
        return moment(x.timestamp).format("DD/MM/YYYY HH:mm");
      }),
      datasets: [
        {
          label: "Max",
          color: "error",
          data: values.map((x) => {
            return x.dangerMax ?? null;
          }),
          backgroundColor: null,
          borderWidth: 1,
          stepped: "middle" as "middle",
        },
        {
          label: "Min",
          color: "error",
          data: values.map((x) => {
            return x.dangerMin ?? null;
          }),
          backgroundColor: null,
          borderWidth: 1,
          stepped: "middle" as "middle",
        },
        {
          label: "Valore",
          color: "info",
          data: values.map((x) => {
            return x.value ?? null;
          }),
          checkError: true,
        },
      ],
    };
  }, [values]);

  const chartRef = useRef<any>(null);
  const [chartData, setChartData] = useState<ReturnType<typeof configs>>({
    data: {
      datasets: [],
    },
    options: {},
  });
  const { data, options } = chartData;

  const lastValuesRef = useRef<ProprietaMisurataValueDto[] | null>(null);

  useEffect(() => {
    const chartDatasets = chart.datasets
      ? chart.datasets.map(
          (dataset): ChartDataset<"line", (number | null)[]> => {
            const getGradientChartLine = (color: any) => {
              return gradientChartLine(
                chartRef.current.children[0],
                colors[color as "dark"]
                  ? colors[(color as "dark") || "dark"].main
                  : colors.dark.main
              );
            };
            const getColor = (color: any) => {
              return colors[color as "dark"]
                ? colors[(color as "dark") || "dark"].main
                : colors.dark.main;
            };

            const normalColor = getColor(dataset.color);
            const errorColor = getColor("error");
            const normalBg = getGradientChartLine(dataset.color);
            const errorBg = getGradientChartLine("error");

            const hasError = (index: number, value: number | null) => {
              const min = values[index].dangerMin;
              const max = values[index].dangerMax;

              const err =
                typeof value === "number"
                  ? typeof min === "number" && value <= min
                    ? true
                    : typeof max === "number" && value >= max
                    ? true
                    : false
                  : false;

              return err;
            };
            const hasErrorSegment = (context: ScriptableLineSegmentContext) => {
              const val0 = context.p0.parsed.y;
              const val1 = context.p1.parsed.y;
              const index0 = context.p0DataIndex;
              const index1 = context.p1DataIndex;
              return hasError(index0, val0) || hasError(index1, val1);
            };

            const getColorWithError = () => {
              return function (context: ScriptableLineSegmentContext) {
                const err = hasErrorSegment(context);
                const c = err ? errorColor : normalColor;
                return c;
              };
            };

            const getBackgroundColorWithError = () => {
              return function (context: ScriptableLineSegmentContext) {
                const err = hasErrorSegment(context);
                const bg = err ? errorBg : normalBg;
                return bg;
              };
            };

            const animation = getAnimation(values, lastValuesRef.current);

            return {
              ...dataset,
              tension: 0, //0.4,
              animation,
              pointRadius: 0,
              borderWidth: dataset.borderWidth ?? 3,
              borderColor: normalColor,
              fill: "start",
              backgroundColor:
                typeof dataset.backgroundColor !== "undefined"
                  ? dataset.backgroundColor || "transparent"
                  : normalBg,

              segment: dataset.checkError
                ? {
                    borderColor: getColorWithError(),
                    backgroundColor: getBackgroundColorWithError(),
                  }
                : undefined,
            };
          }
        )
      : [];

    setChartData(
      configs(chart.labels || [], chartDatasets, proprietaMisurataHelper)
    );
  }, [chart, values, proprietaMisurataHelper]);

  lastValuesRef.current = values;

  return (
    <Box ref={chartRef} sx={{ height: height ?? 300 }}>
      <Line data={data} options={options} />
    </Box>
  );
}

export default ProprietaMisurataHistoryChart;

function getAnimation(
  values: ProprietaMisurataValueDto[],
  last: ProprietaMisurataValueDto[] | null
): false | undefined {
  if (!last) {
    return undefined;
  }
  // è solo un cambiamento di zoom se:
  // - il primo elemento è ancora lo stesso (lo zoom è fatto a destra)
  // - il primo elemento c'era anche prima (lo zoom è fatto a sinistra -> muovendo verso destra)
  // - il primo elemento di prima c'è ancora (lo zoom è fatto a sinistra -> muovendo verso sinistra)
  const firstPre = last[0];
  const firstNext = values[0];
  if (firstPre && firstNext) {
    return firstPre === firstNext ||
      last.includes(firstNext) ||
      values.includes(firstPre)
      ? false
      : undefined;
  }
  return undefined;
}
